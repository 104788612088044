export default [
  {
    label: 'About',
    path: '/',
  },
  {
    label: 'Experience',
    path: '/experience',
  },
  {
    label: 'Projects',
    path: '/projects',
  },
  {
    label: 'Contact',
    path: '/contact',
  },
  {
    label: 'Recommendation',
    path: '/recommendation',
  }
]
